import { HttpHeaders } from '@angular/common/http';

export class Branch {
  public name: string = null;
  public description: string = null;
  public owner: string = null;
  public parent_branch: string = null;
  public created_at: number = null;
  public updated_at: number = null;
  public updated_user: string = null;
  public version: string = null;
  public environments: string[] = [];
  public linked = false;
  public protected = false;

  static create(branch: Partial<Branch> = {}): Branch {
    return Object.assign(new Branch(), branch);
  }

  static fork(branch: Branch): Branch {
    return Object.assign(new Branch(), branch, { name: `${branch.name}-fork` });
  }
}

export class OptimisticLocking {
  private cache: Map<string, string> = new Map();

  constructor(
    private keyBuilder: (key: any) => string = (key: string) => key,
  ) {}

  reset(): void {
    this.cache.clear();
  }

  setKey(key: any, value: string): void {
    const cacheKey = this.keyBuilder(key);

    this.cache.set(cacheKey, value);
  }

  getKey(key: any): string {
    const cacheKey = this.keyBuilder(key);

    return this.cache.get(cacheKey);
  }

  setFromHeaders(key: any, headers: HttpHeaders): void {
    const value = headers.get('ETag');

    if (value != null && value !== '') {
      this.setKey(key, value);
    }
  }

  getCheckHeaders(key: any, headers = new HttpHeaders()): HttpHeaders {
    const value = this.getKey(key);

    if (value != null) {
      return headers.set('If-Match', value);
    }
    return headers;
  }
}

export interface ConfigModel {
  name: string;
  description: string;
  owner: Metadata;
  last: Metadata;
  linked: boolean;
}

export class Config implements Config {
  name = '';
  description = '';
  owner: Metadata;
  last: Metadata;
  linked: boolean;
  isNew = true;
  loading = false;
}

export interface ConfigSections {
  data: { [key: string]: any };
  metadata: any[];
}

export interface ConfigLinks {
  name: string;
  sections: SectionLink[];
}

export interface SectionLink {
  name: string;
  id: string;
  gid: number;
}

export interface Section {
  data: any;
  metadata: any;
}

export interface Environment {
  id: number;
  name: string;
  branch: string;
  last_deployment: {
    author: string;
    timestamp: number;
  };
  uri: string;
  facebook_app_id: string;
  backend_url: string;
  statics_server: string;
  localizations_server: string;
  is_production: boolean;
  config_validation_url: string;
}

export interface Report {
  status: string;
  message: string;
  type: string;
}

export interface GitDiffResponse {
  diff: object | void[];
  conflicts: string[];
  unhandled_output: string[];
}

interface Metadata {
  username: string;
  timestamp: number;
}
