import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { AuthService } from '@app/providers/auth/auth.service';
import { Project, ProjectService } from '@app/providers/projects';
import { NavigationService } from '@app/shared/navigation/navigation.service';
import { TodayService } from '@app/today/today.service';
import { GithubService } from '@app/providers/github/github.service';
import { ProjectDialogComponent } from '@app/shared/project/project-dialog/project-dialog.component';
import { getBuildDate } from '@app/providers/release-control/release-control.model';
import { environment } from '@env/environment';
import * as md5 from 'blueimp-md5';

@Component({
  selector: 'sp-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() sidenav: MatSidenav;
  @Input() showProject = true;

  projects: Project[];
  project: Project;
  userImg: string;
  authenticated = false;
  isAdmin = false;
  githubStatus$ = GithubService.onStatusChange$;
  build: string;
  buildDate: string;

  appInfoStyles = {
    minHeight: '28px',
    lineHeight: '34px',
  };

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private navigationService: NavigationService,
    private changeDetector: ChangeDetectorRef,
    public auth: AuthService,
    public today: TodayService,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'github',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/github.svg'),
    );
    this.build = environment.build;
    this.buildDate = moment(getBuildDate(environment.build)).format('LL HH:mm');
  }

  ngOnInit() {
    this.auth.authenticated$.subscribe((authenticated) => {
      this.authenticated = authenticated;
    });

    this.auth.profile$.subscribe((profile) => {
      const hash = md5(profile.email);
      this.userImg = `https://www.gravatar.com/avatar/${hash}.jpg?s=144&d=no-avatar`;
      this.changeDetector.detectChanges();
    });

    this.auth
      .hasGroup('bs-jarvis/god')
      .then((isAdmin) => (this.isAdmin = isAdmin));

    this.projectService.projects$.subscribe((projects: Project[]) => {
      this.projects = projects;
    });

    this.projectService.project$.subscribe((project: Project) => {
      this.selectProject(project);
    });
  }

  ngAfterViewInit() {
    if (!this.project) {
      this.changeProject();
      this.sidenav.close();
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy() {
    this.changeDetector.detach();
  }

  go(project: Project): void {
    const currentProject = this.project;
    this.projectService.setProject(project);

    const isProjectPage = this.navigationService.isProjectPage();
    if (!isProjectPage) return;

    const noMainToolSelected = !this.navigationService.selectedMainTool;
    const noSelectedProject = currentProject == null;
    const projectDiff =
      JSON.stringify(currentProject) !== JSON.stringify(project);
    const switchPage = !noSelectedProject && !noMainToolSelected;

    if (!this.navigationService.isFirstNavigatedPage && switchPage) {
      this.router.navigateByUrl(this.navigationService.switchProject(project));
    } else if (!this.navigationService.isFirstNavigatedPage && projectDiff) {
      this.router.navigate(['/projects', project.alias, 'home']);
    }
  }

  login(): void {
    this.auth.login().catch(() => {
      this.router.navigate(['/login']);
    });
  }

  getContactUrl(type: string): string {
    let subject = `Jarvis - ${type}`;
    if (this.project) {
      subject += ` - Game: ${this.project.alias}`;
    }
    const mailto = `mailto:bs-jira@socialpoint.es?subject=${encodeURIComponent(
      subject,
    )}`;

    return `https://mail.google.com/mail/?extsrc=mailto&url=${encodeURIComponent(
      mailto,
    )}`;
  }

  changeProject() {
    const config: MatDialogConfig = {
      disableClose: false,
      data: {
        projects: this.projects,
      },
    };
    this.dialog
      .open(ProjectDialogComponent, config)
      .afterClosed()
      .subscribe((project: Project) => {
        if (project && project !== this.project) {
          this.go(project);
        }
      });
  }

  private selectProject(project: Project) {
    const selectedProject = this.projects.find(
      (proj: Project) => project.alias === proj.alias,
    );
    if (selectedProject) {
      this.project = selectedProject;
    }
  }
}
